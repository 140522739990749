<template>
  <div class="cust-dashboard-main">
    <div class="dashboard-header main-header">
      <ul>
        <li>
          <router-link to="/request-driver" tag="a">Create new booking</router-link>
        </li>
        <!-- <li>
          <a href="javascript:;">Find Drivers</a>
        </li> -->
        <li>
          <a
            v-if="is_driver == 1"
            v-on:click="alreadyRegistered"
            style="cursor: pointer"
          >
          Register as a driver</a
          >
          <a v-on:click="registerDriver" v-else style="cursor: pointer">Register as a driver</a>
        </li>
      </ul>
      <div class="dh-info">
        <a @click="redirectToHome()" style="cursor: pointer" target="_blank">FAQ</a>
        <a @click="redirectToHome1()" style="cursor: pointer" target="_blank">About</a>
      </div>
    </div>
    <div class="cd-fold">
      <div class="cd-top">
        <h2>Find local drivers in your area</h2>
        <p>
          Drivers2u allows you to connect with local drivers in your area. Hire your driver for the
          day, week, month or year.
        </p>
        <div class="form-group">
          <!-- <input type="text" class="form-control"
                  placeholder="Search for jobs, drivers and more" />
          <button type="button" class="btn btn-search"><i class="fas fa-search"></i></button>
          <a href="javascript:;">Advanced Search</a> -->
          <search></search>
        </div>
      </div>
      <div class="cd-bottom">
        <h5>We can help you with these</h5>
        <div class="vehical-cat">
          <div class="vehical-list">
            <figure>
              <img :src="require('@assets/images/blue-bike.svg')" alt="" />
            </figure>
            <span>Bike</span>
          </div>
          <div class="vehical-list">
            <figure>
              <img :src="require('@assets/images/blue-car.svg')" alt="" />
            </figure>
            <span>Car</span>
          </div>
          <div class="vehical-list">
            <figure>
              <img :src="require('@assets/images/blue-ute.svg')" alt="" />
            </figure>
            <span>Ute</span>
          </div>
          <div class="vehical-list">
            <figure>
              <img :src="require('@assets/images/blue-truck.svg')" alt="" />
            </figure>
            <span>Truck</span>
          </div>
          <div class="vehical-list">
            <figure>
              <img :src="require('@assets/images/refrigerator.svg')" alt="" />
            </figure>
            <span>Refrigerated Van</span>
          </div>
          <div class="vehical-list">
            <figure>
              <img :src="require('@assets/images/blue-trailer.svg')" alt="" />
            </figure>
            <span>Semi Trailer</span>
          </div>
          <div class="vehical-list">
            <figure>
              <img :src="require('@assets/images/blue-chauffeur.svg')" alt="" />
            </figure>
            <span>Private Chauffeur</span>
          </div>
          <div class="vehical-list">
            <figure>
              <img :src="require('@assets/images/blue-moving-van.svg')" alt="" />
            </figure>
            <span>Moving Van</span>
          </div>
          <div class="vehical-list">
            <figure>
              <img :src="require('@assets/images/van-blue.svg')" alt="" />
            </figure>
            <span>Van</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from './DashboardSearchComponents/Search.vue';
import playstore from '../../assets/images/Playstore.png';
import appstore from '../../assets/images/Appstore.png';
import infoIcon from '../../assets/images/info-icon.png';
import successInfo from '../../assets/images/success-info.png';

export default {
  name: 'Dashboard',
  // eslint-disable-next-line vue/no-unused-components
  components: { Search },
  data() {
    return {
      is_driver: localStorage.getItem('is_driver'),
    };
  },
  /* eslint-disable */
  created() {
    const userdata = JSON.parse(localStorage.getItem('userData'));
    const roleDriver = userdata['roles'].filter((e) => e.name.toLowerCase() == 'driver');
    if (roleDriver.length > 0) {
      this.is_driver = 1;
    } else if (localStorage.getItem('is_driver')) {
      this.is_driver = 1;
    } else {
      this.is_driver = 0;
    }
    // this.$socket.setupSocketConnection();
  },
  methods: {
    redirectToHome() {
      window.open(process.env.VUE_APP_FAQ_REDIRECT, '_blank');
    },
    redirectToHome1() {
      window.open(process.env.VUE_APP_ABOUT_REDIRECT, '_blank');
    },
    alreadyRegistered() {
      this.$swal
        .fire({
          // html:
          //   'You have already registered as a driver <br><br>' +
          //   '<b style="font-size: 14px;">Note : Your credentials is same as your current credentials.</b> <br><br>' +
          //   'Use the app for driver login <br><br>' +
          //   '<div class="row w-100 mx-0 my-0" style="margin-bottom:20px"> <div class="col-6"> <a href="' + process.env.VUE_APP_FAQ_REDIRECT + '"><img style="Height: 60%;" alt="App download image" src="' + playstore + '"></a></div> <div class="col-6"> <a href="' + process.env.VUE_APP_FAQ_REDIRECT + '"><img style="Height: 60%;" alt="App download image" src="' +
          //   appstore + '"></a></div> </div> ',
          // icon: 'info',
          // showConfirmButton: false,
          // showCancelButton: true,
          // cancelButtonColor: '#d33',
          // cancelButtonText: 'Close',
          html:
            "<div class='registerMOdal'><p>You're already registered as a driver.</p>"+
            '<p>Download the driver app to start bidding </p>' +
            '<p>on jobs.</p>' +
            '<p>Use your existing login credentials.</p></div>' +
            '<div class="register-action"><a  target=_blank href="' + process.env.VUE_APP_APPLE_STORE_URL + '"><img style="Height: 60%;" alt="App download image" src="' +
            appstore + '"></a><a target=_blank  href="' + process.env.VUE_APP_PLAY_STORE_URL + '"><img style="Height: 60%;" alt="App download image" src="' + playstore + '"></a></div> ',
            iconHtml: '<img src='+infoIcon+' style="width:90px;">',
          showConfirmButton: false,
          showCancelButton: false,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Close',
          showCloseButton: true,
          closeButtonColor: '#d33',
        });
    },
    registerDriver() {
      this.$swal
        .fire({
          html: "<div style='font-size: 24px!important;font-weight: 500 !important;color: #333;'>Are you sure you want to <br>register as a driver?</div>",
          icon: 'info',
          iconHtml: '<img src='+infoIcon+' style="width:82px;">',
          iconColor:'#219bf3',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.fetchingData = true;
            this.$http
              .post('/customer/register-as-a-driver')
              .then((res) => {
                this.is_driver = 1;
                localStorage.setItem('is_driver', 1);
                this.$swal
                  .fire({
                    html:
                      "<div class='registerMOdal'><p>You're now registered as a driver.</p>"+
                      '<p>Download the driver app to start bidding </p>' +
                      '<p>on jobs.</p>' +
                      '<p>Use your existing login credentials.</p></div>' +
                      '<div class="register-action"><a href="' + process.env.VUE_APP_APPLE_STORE_URL + '"><img style="Height: 60%;" alt="App download image" src="' +
                      appstore + '"></a><a href="' + process.env.VUE_APP_PLAY_STORE_URL + '"><img style="Height: 60%;" alt="App download image" src="' + playstore + '"></a></div> ',
                      iconHtml: '<img src='+successInfo+' style="width:130px;">',
                    showConfirmButton: false,
                    showCancelButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Close',
                    showCloseButton: true,
                    closeButtonColor: '#d33',
                  });
              })
              .catch((error) => {
                this.$toast.error(error.response.data.message);
              })
              .finally(() => {
                this.fetchingData = false;
              });
          }
        });
    },
  },
};
</script>
<style>
  body{
    background-color: #fff !important;
  }
  .swal2-close{
    color:black !important;
    border: unset;
    outline: none;
    box-shadow: unset;
  }
  .swal2-close:focus {outline: none;  box-shadow: unset;}
  .registerMOdal  {margin-bottom: 30px;}
  .registerMOdal p {
    position: relative;
    display: block;
    font-size: 20px;
    line-height: 36px;
    color: #333;
    margin-bottom: 0;
  }
  .register-action {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    height: 70px;
  }
  .register-action a {
    position: relative;
    display: block;
    width: 50%;
    height: 110px;
    overflow: hidden;
    flex: 0 0 50%;

  }
  .register-action a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .vs__actions .vs__clear{
    margin-top: -8px;
  }
</style>
