<template>
<div>
  <div class="form-group">
      <input type="text" class="form-control"
      :placeholder="(serverParams.suburb_option || (serverParams.search))
      ? '' : 'Search for a location'" />
      <!-- <v-select
        class="mt-2"
        v-model="serverParams.vehicle_id1"
        :options="vehicle_option"
        label="name"
        :reduce="(e) => e.id"
      /> -->
       <button type="button" class="btn btn-search"><i class="fas fa-search"></i></button>
      <v-select
        @search="getSuburb1"
        @input ="selectSuburb(1)"
        v-model="serverParams.suburb_option"
        :options="suburb_option"
        label="place_name"
        class="mt-2"
      >
        <span slot="no-options">
           {{ noOptionMessage }}
        </span>
        <template #selected-option="{ place_name , state_name }">
          {{ place_name }} , {{ state_name }}
        </template>
        <template #option="{ place_name , state_name }">
          <h6 style="margin: 0">{{ place_name }} ,
            {{ state_name }}</h6>
        </template>
      </v-select>
  </div>
      <!-- <select
        class="form-select"
        v-model="serverParams.suburb_option"
        @change="selectSuburb(0)"
      >
        <option value="">Suburb</option>
        <option
          v-for="(suburb,index) in suburb_option"
          v-bind:key="index"
          v-bind:value="index"
        >
          {{ suburb.place_name }} - {{ suburb.state_name }}
        </option>
      </select> -->
      <div class="form-group">
        <select
          class="form-select"
          v-model="serverParams.vehicle_id1"
        >
          <option value="null" hidden>Choose vehicle</option>
          <option
            v-for="option in this.vehicle_option"
            v-bind:key="option.id"
            v-bind:value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>

      <div v-if="display_div">
        <div class="form-group">
          <select class="form-select" v-model="serverParams.search_by_rating">
            <option selected value="null">Choose Rating</option>
            <option value="5">5 star</option>
            <option value="4">4 star</option>
            <option value="3">3 star</option>
            <option value="2">2 star</option>
            <option value="1">1 star</option>
          </select>
        </div>
        <div class="form-group">
          <select class="form-select" v-model="serverParams.search_by_verification">
            <option selected value="">Driver Verification Type</option>
            <option value="Verified">Verified</option>
            <option value="Verifed Now">Unverified</option>
          </select>
        </div>
      </div>
      <!-- <a href="javascript:;" v-on:click="toggleDiv()"
      class="d-block mt-2 advance-search">Advanced Search</a> -->
      <router-link tag='button'  class="btn btn-primary mt-2"
        :to="{ name: 'searchresult', params: { tag: this.serverParams }}" >
         search
      </router-link>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */

export default {
  name: 'Search',
  components: { },
  data() {
    return {
      vehicle_option: [],
      suburb_option: [],
      isSearchSubmit: false,
      noOptionMessage: 'Please enter minimum 3 characters for search',
      serverParams: {
        // vehicle_id1: 4,
        suburb_option: '',
        search: '',
        vehicle_id1: null,
        search_by_rating: null,
        search_by_verification: '',
        page: 1,
        perPage: 10,
        latitude: '',
        longitude: '',
      },
      display_div: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getVehicles();
    if (this.serverParams.search) {
      this.getSuburb();
    }
  },
  methods: {
    getVehicles() {
      this.$http
        .get('/getVehicalTypes')
        .then((res) => {
          this.vehicle_option = res.data.data;
        })
        .catch((error) => {
          this.$toast.error('Vehicle not found');
        })
        .finally(() => {});
    },
    /* eslint-disable */

    toggleDiv(){
      this.display_div = !this.display_div;
      if(!this.display_div){
        this.serverParams.search_by_rating =  null;
        this.serverParams.search_by_verification = '';
      }
    },
    /* eslint-disable */
    selectSuburb(type) {
      if (type === 0) {
        if (this.serverParams.suburb_option) {
          this.serverParams.latitude = this.suburb_option[this.serverParams.suburb_option].latitude;
          this.serverParams.longitude = this.suburb_option[this.serverParams.suburb_option].longitude;
        } else {
          delete this.serverParams.latitude;
          delete this.serverParams.longitude;
        }
      }
      if (type === 1) {
        const isSubFind = this.suburb_option.findIndex((x) => x === this.serverParams.suburb_option);
        if (isSubFind  > -1) {
          this.serverParams.latitude = this.serverParams.suburb_option.latitude;
          this.serverParams.longitude = this.serverParams.suburb_option.longitude;
        } else {
          delete this.serverParams.latitude;
          delete this.serverParams.longitude;
        }
      }
    },
    getSuburb() {
      this.$http
        .get('/getSuburbs', { params: { ...this.serverParams } })
        .then((res) => {
          this.suburb_option = res.data.data;
        })
        .catch((error) => {
          this.$toast.error('Suburb not found');
        })
        .finally(() => {});
    },
     // eslint-disable-next-line no-unused-vars
    getSuburb1(search, loading) {
      this.serverParams.search = search;
      if (this.serverParams.search.length > 2) {
        this.noOptionMessage = 'Sorry, no matching options';
        this.getSuburb();
      }
      if (this.serverParams.search.length <= 2) {
        this.noOptionMessage = 'Please enter minimum 3 characters for search';
        this.suburb_option = [];
      }
    },
  },
};
</script>
<style scoped>

.review-spinner.spinner-border{
    height: 1rem !important;
    width:  1rem !important;
}
</style>
